import React, {useEffect, Component } from "react";
import Footer from "../../component/footer";
import HeroSection from "../../component/herosection/HeroSection";
import NavCtn from "../../component/navbar";
import Button from "@restart/ui/esm/Button";
import supportimg from "../../img/support-img.png";
import "./Support.css";
import Aos from "aos";
import "aos/dist/aos.css";


const Support = () => {
  
   useEffect(() => { 
     Aos.init({
       duration: 2000,
       once: true,
       mirror: false,
       disable: "mobile",
     });
   }, []);
  return (
    <div className="w-100 overflow-hidden ">
      <NavCtn />
      <HeroSection captionTop="How can we help?" />
      <section className="w-100  d-flex my-5">
        <div className="contact-us mx-auto card border-0 support-card ">
          <div className="row gy-3 px-3 px-lg-5 px-md-5 px-xxl-5">
            <div className="col-sm-12 col-md-5 col-md-5 pe-5">
              <h3
                className="form-header"
                data-aos="fade-up"
                style={{ marginBottom: "16px" }}
              >
                Contact Sales
              </h3>
              <p
                className="form-subtext"
                data-aos="fade-up"
                style={{ marginBottom: "16px" }}
              >
                Tell us how we can help and our team is happy to answer your
                questions and give you more details about our product
              </p>
              <div>
                <img src={supportimg} data-aos="fade-up" />
              </div>
            </div>
            <div className="col-sm-12 col-md-7 col-md-7 px-4">
              <form>
                <div class="mb-3 row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label for="fname" class="form-label" data-aos="fade-up">
                      First Name
                    </label>
                    <input
                      type="text"
                      class="form-control support-input"
                      id="fname"
                      aria-describedby="emailHelp"
                      placeholder="First Name"
                      data-aos="fade-up"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label for="lname" class="form-label" data-aos="fade-up">
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      class="form-control support-input"
                      id="lname"
                      data-aos="fade-up"
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label for="email" class="form-label" data-aos="fade-up">
                      Email
                    </label>
                    <input
                      type="Email"
                      class="form-control support-input"
                      id="email"
                      placeholder="Email"
                      aria-describedby="emailHelp"
                      data-aos="fade-up"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label for="cname" class="form-label" data-aos="fade-up">
                      Company Name
                    </label>
                    <input
                      type="text"
                      class="form-control support-input"
                      id="cname"
                      placeholder="Company name"
                      aria-describedby="emailHelp"
                      data-aos="fade-up"
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <label for="selector" class="form-label" data-aos="fade-up">
                    Your Inquiry is about?
                  </label>
                  <div class="dropdown">
                    <button
                      class="btn btn-block dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="selector"
                      class="form-select support-input-select"
                      data-aos="fade-up"
                    ></button>
                    <ul
                      class="dropdown-menu w-100"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" href="#">
                          General Info Request
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Partner Relations
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Software Licensing
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="selector" class="form-label" data-aos="fade-up">
                    Message
                  </label>
                  <textarea
                    className="form-control support-input-fill"
                    data-aos="fade-up"
                  ></textarea>
                </div>

                <div className="btn-ctn d-flex w-100 justify-content-end ">
                  <Button
                    className="btn btn-danger mt-4 homepage_left_button"
                    data-aos="fade-up"
                  >
                    Send Message
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="w-100   mt-5 pt-5">
        <h3 className="faq px-5 pb-2 ">Frequently Asked Questions</h3>
        <p className="faq-sub-text px-5 ">Need answers? Find them here</p>

        <div className="row w-100 m-0 mb-3">
          <div className="col-sm-12 p-0">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item ">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed btn-block border-0 px-5 "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsibleOne"
                    aria-expanded="false"
                    aria-controls="collapsibleOne"
                  >
                    What is Blacklist?
                  </button>
                </h2>
                <div
                  id="collapsibleOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body px-5 pb-5">
                    Blacklist Limited is a credit management company helping
                    businesses make better credit decisions and individuals to
                    become debt-free. One of the products of Blacklist Limited
                    is the blacklist database which is an alternative database
                    for chronic loan defaulters and fraudulent people as
                    reported by verified registered online lending companies.
                    Blacklist Limited also provides access to external databases
                    that contribute to building a consumer credit profile,
                    against which credit decisions can be made about prospective
                    borrowers, beneficiaries, or customers in general.
                    <p className="mt-3">
                      {" "}
                      As a registered lender, your organization can have access
                      to data in the Blacklist especially when the need to
                      verify the status of prospective borrowers, beneficiaries
                      or customers arises.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row w-100 m-0 mb-3">
          <div className="col-sm-12 p-0">
            <div class="accordion" id="accordion2">
              <div class="accordion-item ">
                <h2 class="accordion-header" id="header-2">
                  <button
                    class="accordion-button collapsed btn-block border-0 px-5 "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsibleTwo"
                    aria-expanded="false"
                    aria-controls="collapsibleTwo"
                  >
                    How can one access the blacklist & external databases?
                  </button>
                </h2>
                <div
                  id="collapsibleTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="header-2"
                  data-bs-parent="#accordion2"
                >
                  <div class="accordion-body px-5 pb-5">
                    Access to the databases is open to registered members of the
                    community, manually or via API integration. First,
                    registered businesses would need to sign up onto the
                    platform (click Sign Up on{" "}
                    <a
                      href="www.blacklistng.com"
                      target="_blank"
                      style={{ color: "#4b1616" }}
                    >
                      www.blacklistng.com
                    </a>
                    ), sign-up is free and only takes a couple of minutes. Upon
                    sign-up, a member account is created and you will be
                    required to upload documents for business verification.
                    Approval may take 24-48 hours. Once successfully verified, a
                    member will be able to perform manual searches at the
                    stipulated fee. For automated searches, API integration will
                    be required. You may review the API documentation here
                    <a
                      href=" https://blacklistng.com/docs/"
                      style={{ color: "#4b1616" }}
                      target="_blank"
                    >
                      {" "}
                      https://blacklistng.com/docs/
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row w-100 m-0 mb-3">
          <div className="col-sm-12 p-0">
            <div class="accordion" id="accordion3">
              <div class="accordion-item ">
                <h2 class="accordion-header" id="header-3">
                  <button
                    class="accordion-button collapsed btn-block border-0 px-5 "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsibleThree"
                    aria-expanded="false"
                    aria-controls="collapsibleThree"
                  >
                    How robust is the blacklist database?
                  </button>
                </h2>
                <div
                  id="collapsibleThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="header-3"
                  data-bs-parent="#accordion3"
                >
                  <div class="accordion-body px-5 pb-5">
                    Together with our external database connections, members on
                    the blacklist platform have access to over 55 million data
                    records.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row w-100 m-0 mb-3">
          <div className="col-sm-12 p-0">
            <div class="accordion" id="accordion3">
              <div class="accordion-item ">
                <h2 class="accordion-header" id="header-3">
                  <button
                    class="accordion-button collapsed btn-block border-0 px-5 "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsibleFour"
                    aria-expanded="false"
                    aria-controls="collapsibleFour"
                  >
                    How does the platform protect the financial privacy of data
                    subjects?
                  </button>
                </h2>
                <div
                  id="collapsibleFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="header-3"
                  data-bs-parent="#accordion3"
                >
                  <div class="accordion-body px-5 pb-5">
                    As per our financial privacy policy, the data supplier and
                    data requestor are each required to have obtained the
                    consent of the data subjects/ customers to upload financial
                    information and/ or request financial information
                    respectively. The data supplier is responsible for the
                    accuracy and correctness of the information they provide on
                    the platform.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row w-100 m-0 mb-3">
          <div className="col-sm-12 p-0">
            <div class="accordion" id="accordion3">
              <div class="accordion-item ">
                <h2 class="accordion-header" id="header-3">
                  <button
                    class="accordion-button collapsed btn-block border-0 px-5 "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsibleFive"
                    aria-expanded="false"
                    aria-controls="collapsibleFive"
                  >
                    Is sign up on Blacklist free?
                  </button>
                </h2>
                <div
                  id="collapsibleFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="header-3"
                  data-bs-parent="#accordion3"
                >
                  <div class="accordion-body px-5 pb-5">
                    Yes, signing up on Blacklist is free and takes only a couple
                    of minutes.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Support;
