import React, { Component, useEffect, useState } from "react";
import mapmobile from "../../img/Map-mobile.png";
import map2 from "../../img/Map2.png";
import "aos/dist/aos.css";
import Aos from "aos";

const GetridCaptionSection = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false });
  });
  const [imageFix, setImageFix] = useState(false);
  const [otherImage, setOtherImage] = useState(false);
  const imageFixer = () => {
    if (window.innerWidth <= 1270) {
      // console.log(window.innerWidth);
      return setImageFix(true);
    } else {
      return setImageFix(false);
    }
  };
  const imageChanger = () => {
    if (window.innerWidth <= 660) {
      return setOtherImage(true);
    } else {
      return setOtherImage(false);
    }
  };
  window.addEventListener("resize", imageFixer);
  window.addEventListener("load", imageFixer);
  window.addEventListener("scroll", imageFixer);
  window.addEventListener("focus", imageFixer);

  window.addEventListener("resize", imageChanger);
  window.addEventListener("load", imageChanger);
  window.addEventListener("scroll", imageChanger);
  window.addEventListener("focus", imageChanger);

  return (
    <section className="red-section-bg py-4 w-100">
      <div className="row  justify-content-between">
        <div className="col-sm-12 col-md-6  col-lg-6 ps-sm-5 pe-0 d-flex">
          <h3
            className="title-red-section my-auto offset-lg-1"
            data-aos="fade-right"
          >
            Getting rid of chronic <br />
            <p className="whitespace-wrapper">
              defaulters one search at a time
            </p>
          </h3>
        </div>
        {imageFix ? (
          <div className="d-flex justify-content-end px-0" data-aos="fade-left">
            <img src={otherImage ? mapmobile : map2} data-aos="fade-left" />
          </div>
        ) : (
          <div className="col-auto px-0">
            <img src={map2} data-aos="fade-left" />
          </div>
        )}
      </div>
    </section>
  );
};

export default GetridCaptionSection;
