import React, { Component, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import Footer from "../../component/footer";
import GetridCaptionSection from "../../component/GetridCaptionSection.jsx/GetridCaptionSection";
import HeroSection from "../../component/herosection/HeroSection";
import NavCtn from "../../component/navbar";
import textFill from "../../img/loader-bg.png";
import val1 from "../../img/val-1.png"
import val2 from "../../img/val-2.png"
import val3 from "../../img/val-3.png";
import val4 from "../../img/val-4.png";

import "./style.css";
import "aos/dist/aos.css";
import Aos from "aos";
 
const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false, disable: "mobile" })
  },[]);
  return (
    <div className="overflow-hidden w-100">
      <NavCtn />
      <HeroSection
        captionTop="Prevention Better "
        captionBottom="Than Recovery"
      />
      <section className="overflow-hidden w-100 middle-section py-5">
        <div className="row justify-content-between mt-lg-5 mx-lg-5 mx-3 mb-2">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <h3 className="second_level_header" data-aos="fade-up">
              We are a mission
              <br />
              driven company
            </h3>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 pt-sm-3 pt-sx-3 pt-md-3 pt-0">
            <p className="explain-text" data-aos="fade-up">
              We are a credit management company helping businesses make better
              credit decisions and borrowers to become debt-free. Non-performing
              loans are a big issue for the credit industry, both for lenders
              and borrowers. Businesses need to be proactive in reducing bad
              loans, this would mean investing in bad debt prevention and this
              requires an increased focus on risk assessment prior to giving
              gout credit.
            </p>
            <p className="explain-text" data-aos="fade-up">
              The Blacklist a robust and dynamic database of chronic defaulters
              is built to help lending companies gain wholesome insight into
              prospective borrowers’ willingness and capability to repay loans,
              through access to their past and active credit information.
            </p>
            <p className="explain-text" data-aos="fade-up">
              While there may be valid reasons why some good-willing customers
              are unable to repay their debts on or before the due date,
              defaulting on loan repayments taints one’s credit record and could
              prevent or limit further access to credit. Our AI-powered platform
              service Restructr, helps borrowers to restructure their loans and
              get them out of debt quickly in order to raise their
              creditworthiness. Restructr also serves as a platform for lenders
              to follow up on debt recovery from their blacklisted records.
            </p>
          </div>
        </div>
      </section>
      <section className="overflow-hidden w-100 middle-section py-5">
        <div className="row justify-content-between mt-lg-5 mx-lg-4 mx-3 mb-2">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <h3 className="second_level_header" data-aos="fade-up">
              Our Values
            </h3>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 pt-sm-3 pt-sx-3 pt-md-3 pt-0">
            <div className="row gx-5 gy-5">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                <div className="card special-card border-0" data-aos="fade-up">
                  <div className="card-body p-4">
                    <div className="card-img-top mt-4 mb-5">
                      <img src={val1} alt="integrity" />
                    </div>
                    <div className="card-title">Integrity</div>
                    <p className="card-subtitle">
                      We strive to do the right thing always
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                <div className="card special-card border-0" data-aos="fade-up">
                  <div className="card-body p-4">
                    <div className="card-img-top mt-4 mb-5">
                      <img src={val2} alt="security" />
                    </div>
                    <div className="card-title">Data Security & Privacy</div>
                    <p className="card-subtitle">
                      We are commited to protecting our customers’ data
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xxl-6">
                <div className="card special-card border-0" data-aos="fade-up">
                  <div className="card-body p-4">
                    <div className="card-img-top mt-4 mb-5">
                      <img src={val3} alt="Customer-centric" />
                    </div>
                    <div className="card-title">Customer-centric</div>
                    <p className="card-subtitle">
                      Understanding our customer's needs and designing solutions
                      that deliver the best
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xxl-6">
                <div className="card special-card border-0 " data-aos="fade-up">
                  <div className="card-body px-3">
                    <div className="card-img-top mt-4 mb-5">
                      <img src={val4} alt="integrity" />
                    </div>
                    <div className="card-title">Community</div>
                    <p className="card-subtitle">
                      We are commited to protecting our customers’ data
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GetridCaptionSection />
      <Footer />
    </div>
  );
};

export default AboutUs;
