import React, { Component, useEffect } from "react";
import business from "../../img/business.png";
import customer from "../../img/consumer-icon.svg";
import lender from "../../img/lender-icon.svg";
import Button from "@restart/ui/esm/Button";
import "./ProductOfferingSection.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const ProductOfferingSection = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false });
  });
  return (
    <section className="homepage_3 py-5 ">
      <div className="my-4  mx-4 ">
        <p className="second_level_header text-center" data-aos="fade-up">
          Product Offering
        </p>
        <div className="row my-3 gy-3">
          <div className=" col-md-4 col-sm-12 col-lg-4" data-aos="fade-up">
            <div className="cardElement card border-0 py-5 h-100">
              <img className="business card-image mx-auto mb-3" src={lender} />
              <p className="card-title-top">Blacklist for Lenders</p>
              <div className="w-100 card-list px-4">
                {" "}
                <ul className="">
                  <li> Access real-time data of chronic defaulters</li>
                  <li>
                    {" "}
                    Access multiple credit data sources through a single
                    interface.
                  </li>
                  <li> Improve your risk assessment process</li>
                  <li> Get paid anytime your data gets hit</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 col-lg-4" data-aos="fade-up">
            <div className="cardElement card border-0 py-5 h-100">
              <img
                className="customer card-image mx-auto mb-3"
                src={customer}
              />
              <p className="card-title-top">Blacklist for Individuals</p>
              <div className="w-100 card-list px-4">
                {" "}
                <ul className="">
                  <li>Perform credit check</li>
                  <li> See your credit status on the go</li>
                  <li>Start your journey to becoming debt-free</li>
                  <li>Increase your credit-worthiness</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 col-lg-4" data-aos="fade-up">
            <div className="cardElement card border-0 py-5 h-100">
              <img className="lender card-image mx-auto mb-3" src={business} />
              <p className="card-title-top">Blacklist for Businesses</p>

              <div className="w-100 card-list px-4">
                <ul className="">
                  <li>
                    {" "}
                    Assess fiscal responsibility of prospective employees,
                    customers, tenants, etc
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </section>
  );
};

export default ProductOfferingSection;
