import React, { Component, useEffect, useState } from "react";
import "./style.scss";
import menuicon1 from "../../img/line.svg";
import menuicon2 from "../../img/broken.svg";
import logo from "../../img/logo.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import { Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";

const NavCtn = () => {
  useEffect(() => {
    Aos.init({ duration: 3000, once: true, mirror: false });
  });
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
    console.log(click)
  };

  const defaultChange = () => {
    if (window.innerWidth <= 992) {
      setClick(false);
      console.log(click, "changeEvent")
    }if (window.innerWidth >= 992) {
      setClick(false);
      console.log(click, "changeEvent");
    }
  };

  window.addEventListener("resize", defaultChange);
    // window.addEventListener("", defaultChange);

  return (
    <Navbar onChange={defaultChange}
      bg="white"
      expand="lg"
      className={
        click
          ? "px-lg-5 py-3  w-100 hamburger-ctn activeClick"
          : " px-lg-5 py-3  w-100"
      }
      // data-aos="fade-down"
    >
      <div class="container-fluid h-100 nav-ctn ">
        <Link class="navbar-brand" to="/">
          <img src={logo} />
        </Link>
        <button
          id="hamburger"
          className="navbar-toggler border-0 hamburger-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleClick}
        >
          <span className={click ? "burger opened  " : " burger "}>
            <img
              className={click ? "middleline my-auto" : "my-auto"}
              src={menuicon2}
            />
          </span>
        </button>
        <div
          className={
            click
              ? "w-100 c navbar-collapse-ctn-alt"
              : " navbar-collapse-ctn d-flex collapse w-100"
          }
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav navlist-1 me-auto mb-2 mb-lg-0 mb-md-0 mb-sm-0 mb-xs-0 first-list">
            <li class="nav-item">
              <Link class="nav-link active" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link active" to="/solutions">
                Solutions
              </Link>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle active"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Learn
              </a>
              <ul
                class="dropdown-menu border-alt"
                aria-labelledby="navbarDropdown"
              >
                <li className="list-item1">
                  <Link class="dropdown-item " to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li className="list-item2">
                  <Link class="dropdown-item " to="/blog">
                    Blog
                  </Link>
                </li>
              </ul>
            </li>
            <li class="nav-item hidden-item">
              {/* <Link class="nav-link active" to="/"> */}
              <a class="nav-link active" href="https://blacklistng.com/docs">
                Developers
              </a>
              {/* </Link> */}
            </li>
            <li class="nav-item hidden-item">
              <Link class="nav-link active" to="/support">
                Support
              </Link>
            </li>
          </ul>
          <ul class="navbar-nav navlist-2 ms-auto mb-2 mb-lg-0 me-lg-5 me-md-5 me-xl-5 me-xxl-5    offset-md-1 ms-md-1">
            <li class="nav-item ms-lg-auto ms-md-4 nav-item-align">
              <a
                class="nav-link active"
                href="https://blacklistng.com/web/auth"
              >
                Login
              </a>
            </li>
          </ul>

          <a
            href="https://blacklistng.com/web/register"
            data-aos="fade-down"
            className="createaccntbbtn"
            data-aos-delay="600"
          >
            <button
              class="btn px-4 py-2  homepage_left_button_learn  btn-outline-danger  mx-auto"
              style={{ whiteSpace: "nowrap" }}
            >
              Create an Account
            </button>
          </a>
        </div>
      </div>
    </Navbar>
  );
};
export default NavCtn;
