import React, { Component, useEffect, useState } from "react";
import Footer from "../../component/footer";
import GetridCaptionSection from "../../component/GetridCaptionSection.jsx/GetridCaptionSection";
import HeroSection from "../../component/herosection/HeroSection";
import NavCtn from "../../component/navbar";
import ProductOfferingSection from "../../component/productofferingsection/ProductOfferingSection";
import methodologyImage from "../../img/Blacklist-Methodology.png";
import methodologyImageMobile from "../../img/Blacklist-Methodology-Mobile.png";
import num1 from "../../img/Icon 1.svg";
import num2 from "../../img/Icon 2.svg";
import num3 from "../../img/Icon 3.svg";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Solutions = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false, disable: "mobile" })
  }
  );
  const [otherImage, setOtherImage] = useState(false);

  const imageChanger = () => {
    if (window.innerWidth <= 800) {
      return setOtherImage(true);
    } else {
      return setOtherImage(false);
    }
  };
  window.addEventListener("resize", imageChanger);
  window.addEventListener("load", imageChanger);
  window.addEventListener("scroll", imageChanger);
  window.addEventListener("focus", imageChanger);
  return (
    <div className="overflow-hidden w-100">
      <NavCtn />
      <HeroSection
        captionTop="Access Relevant Data Reports,"
        captionBottom=" For Better Credit Decisions"
      />
      <section className="middle-section w-100 overflow-hidden py-5">
        <div className="row justify-content-between mt-lg-5 mx-lg-5 mx-3 mb-2">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <h3 className="second_level_header" data-aos="fade-up">
              Preamble
            </h3>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 pt-sm-3 pt-sx-3 pt-md-3 pt-0">
            <p className="explain-text" data-aos="fade-up">
              Credit is critical to growing an economy, however, reports have it
              that over 40% of the Nigerian population is financially excluded
              and thus has limited access to credit. In response to this gap, we
              have seen an explosive rise in the number of fintechs who are
              extending financial services including credit access to the
              unbanked and under-banked population
            </p>
          </div>
        </div>
        <div className="row justify-content-between mt-lg-5 mx-lg-5 mx-3 mb-2">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <h3 className="second_level_header" data-aos="fade-up">
              The Problem
            </h3>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 pt-sm-3 pt-sx-3 pt-md-3 pt-0">
            <p className="explain-text" data-aos="fade-up">
              While it’s gotten easier to access credit, non-performing loans
              are on the rise, sometimes to unmanageable levels, resulting in
              lenders sometimes suspending their operations, or totally folding
              up. One of the major reasons for rising non-performing loans (NPL)
              is due to inadequate credit checks by lenders as well as people
              gaming the loan system as the unbanked and underbanked population
              are not covered by traditional credit databases . Hence, the need
              for alternative sources of credit data. On the other hand, debtors
              are unable to pay back sometimes for understandable reasons. Or
              become elusive due to threatening collection processes by some
              lenders and collection agencies, et cetera.
            </p>
          </div>
        </div>
        <div className="row justify-content-between mt-lg-5 mx-lg-5 mx-3 mb-2">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <h3 className="second_level_header" data-aos="fade-up">
              The Solutions
            </h3>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 pt-sm-3 pt-sx-3 pt-md-3 pt-0">
            <p className="explain-text" data-aos="fade-up">
              The Blacklist operates an alternative database of chronic credit
              defaulters and fraudulent people aggregated from our community of
              fintech lenders and other associated databases that clients can
              run checks against thereby improving the credit decision making at
              the underwriting level The Blacklist provides versatile credit
              checks and automates the lending and credit decision process for
              Fintech lenders through API calls. Through its ethical collections
              systems, Restructr, debtors are also provided with flexible
              repayment options through Artificial intelligence to help them
              eventually become debt- free.
            </p>
          </div>
        </div>
      </section>
      <section className="methodology  w-100">
        <div className="row mt-5 w-100 mx-0">
          <div className="col-sm-12">
            <p className="second_level_header text-center" data-aos="fade-up">
              Blacklist Methodology
            </p>
          </div>
          <div className="col-sm-12 d-flex py-3 px-5">
            <img
              src={otherImage ? methodologyImageMobile : methodologyImage}
              alt="Blacklist Methodology"
              className="mx-auto img-fluid"
              data-aos="fade-up"
            />
          </div>
        </div>
        <div className="row  mb-5 w-100 mx-0 justify-content-evenly row-alt ps-sm-3 ps-xs-3 ">
          <div className="col-auto d-flex">
            <div
              className="card description-card border-0 mx-auto mt-md-4 mb-sm-3 mb-xs-3"
              data-aos="fade-up"
            >
              <div className="card-body p-4">
                <div className="py-4">
                  <img src={num1} className="card-img-top " />
                </div>
                <p className="methodology-card-caption">
                  Search prospective borrower's unique ID (BVN, email, name, or
                  phone number)
                </p>
              </div>
            </div>
          </div>
          <div className="col-auto d-flex">
            <div
              className="card description-card border-0 mx-auto mt-md-4 mb-sm-3 mb-xs-3"
              data-aos="fade-up"
              data-aos-delay="1000"
            >
              <div className="card-body p-4">
                <div className="py-4">
                  <img src={num2} className="card-img-top " />
                </div>
                <p className="methodology-card-caption">
                  Search runs through the Blacklist and other databases selected
                  by lender.
                </p>
              </div>
            </div>
          </div>
          <div className="col-auto d-flex">
            <div
              className="card description-card-alt border-0 mx-auto mt-md-4 mb-sm-3 mb-xs-3"
              data-aos="fade-up"
              data-aos-delay="1300"
            >
              <div className="card-body p-4">
                <div className="py-4 ">
                  <img src={num3} className="card-img-top " />
                </div>
                <p className="methodology-card-caption">
                  a) Search will return a positive report, if prospective
                  borrower is a defaulter in the blacklist.
                </p>
                <p className="methodology-card-caption pt-2">
                  b) Search will return a negative report, if prospective
                  borrower is not a defaulter in our system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProductOfferingSection />
      <GetridCaptionSection />
      <Footer />
    </div>
  );
};

export default Solutions;
