import Aos from "aos";
import React, { Component, useEffect } from "react";
import styled from "styled-components";
import "aos/dist/aos.css";

const Caption = styled.h3`
  font-size: 48px !important;
  font-weight: 600;
  line-height: 139.4%;
  color: #4b1616;
`;
const HeroSection = (props) => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false });
  });
    return (
      <section
        id="reusable-herosection w-100 overflow-hidden "
        style={{ backgroundColor: "#FFF2F2", height: "73vh" }}
      >
        <div className="w-100 h-100 d-flex py-5 px-2">
          <Caption
            className="text-center caption-text mx-auto my-auto"
            data-aos="zoom-out"
          >
            {props.captionTop} <br /> {props.captionBottom}
          </Caption>
        </div>
      </section>
    );
        
};

export default HeroSection;
