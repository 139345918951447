import React, { useEffect, useState, useRef } from "react";
import { emailjs, sendForm } from "emailjs-com";
import successimg from "../../img/send-success.png";
import "./style.css";
import close from "../../img/cancel.svg";
import hero from "../../img/Dashboard.png";
import hero2 from "../../img/Dashboard-mobile.png";
import play from "../../img/play_button.png";
import map from "../../img/Map.png";
import tech from "../../img/techpoint.png";
import venture from "../../img/venture.png";
import teke from "../../img/Tekemedia.png";
import Footer from "../../component/footer/index";
import logo from "../../img/logo.svg";
import FB from "../../img/fb.png";
import twitter from "../../img/twitter.png";
import Button from "@restart/ui/esm/Button";
import { Container, Col, Row } from "react-bootstrap";
import NavCtn from "../../component/navbar";
import ProductOfferingSection from "../../component/productofferingsection/ProductOfferingSection";
import GetridCaptionSection from "../../component/GetridCaptionSection.jsx/GetridCaptionSection";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import Colaborations from "../../component/collaborations/ColaborationSection";
export default function Homepage() {
  const [success, setSuccess] = useState(false);
  const [form, setForm] = useState({
    lname: "",
    fname: "",
    email: "",
    cname: "",
    inquiry: "",
    message: "",
  });

  const base_URL = "https://api.emailjs.com/api/v1.0/email/send-form";
  const sendEmail = (e) => {
    e.preventDefault();
    // console.log(typeof(form));
    let formData = { service_id: "service_7958vjp" , template_id: "template_p0h1wtt", user_id: "user_7RcWSi2Vi7YrueeZ2RkbH"};
    // console.log(formData);
    formData = Object.assign(formData,form);
    // console.log(formData);
    // formData.service_id = "service_7958vjp";
    // formData.template_id= "template_p0h1wtt";
    // formData.user_id= "user_7RcWSi2Vi7YrueeZ2RkbH";
    console.log(formData);

    $.ajax("https://test.blacklistng.com/mails/demo.php", {
      type: "POST",
      data: formData,
      contentType: false, // auto-detection
      processData: false, // no need to parse formData to string
    })
      .done(function () {
        setSuccess(true)
        alert("Your mail is sent! We will get back to you within 24 hours");
      })
      .fail(function (error) {
        setSuccess(false)
        alert("Oops... " + JSON.stringify(error));
      });
    console.log(formData);
    e.target.reset();
  };

  const updateForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false, disable: "mobile" });
  }, []);
  const [imageFix, setImageFix] = useState(false);
  const [otherImage, setOtherImage] = useState(false);
  const imageFixer = () => {
    if (window.innerWidth <= 1270) {
      // console.log(window.innerWidth);
      return setImageFix(true);
    } else {
      return setImageFix(false);
    }
  };
  const imageChanger = () => {
    if (window.innerWidth <= 660) {
      return setOtherImage(true);
    } else {
      return setOtherImage(false);
    }
  };
  window.addEventListener("resize", imageFixer);
  window.addEventListener("load", imageFixer);
  window.addEventListener("scroll", imageFixer);
  window.addEventListener("focus", imageFixer);

  window.addEventListener("resize", imageChanger);
  window.addEventListener("load", imageChanger);
  window.addEventListener("scroll", imageChanger);
  window.addEventListener("focus", imageChanger);

  return (
    <div className="w-100 overflow-hidden">
      <NavCtn id="nav" />
      <section className="section-1 w-100 " style={{ background: "#FFF2F2" }}>
        <Row className="justify-content-between w-100 m-0">
          <Col
            xxl={5}
            xl={5}
            lg={5}
            md={5}
            sm={12}
            className=" top_header  ps-4"
            data-aos="fade-bottom"
          >
            <div className="ms-lg-5">
              <div
                className=" px-2 py-2 d-flex space-between homepage_left_item1 ms-0"
                style={{ maxWidth: "420px" }}
                // data-aos="zoom-out-right"
                // data-aos-delay="1000"
              >
                <Button
                  className="homepage_left_button1 my-auto btn-outline-danger btn font-resize-alt"
                  href="https://medium.com/@blacklistnigeria/blacklist-limited-enters-strategic-partnership-with-crc-credit-bureau-nigeria-c900c1f9ba5a"
                  target="_blank"
                >
                  New
                </Button>{" "}
                <marquee scrollamount="5">
                  <p className="homepage_left_text my-auto mx-3 font-resize-alt">
                    <span class="mx-3">Announcing Partnership with CRC Credit Bureau <a href="https://medium.com/@blacklistnigeria/blacklist-limited-enters-strategic-partnership-with-crc-credit-bureau-nigeria-c900c1f9ba5a" target="_blank">Learn More</a></span>        ||
                    <span class="mx-3"> We are Evolving! Learn more about our new brand identity <a href="https://bit.ly/3kGqsiz" target="_blank">Learn more</a></span>
                  </p>
                </marquee>
                {/* <marquee>
                  <p className="homepage_left_text my-auto mx-3 font-resize-alt">
                    
                  </p>
                </marquee> */}
              </div>
              <div className="homepage_left_item2 my-3 py-1">
                <p
                  className="homepage_left_item1Text1 my-4 mb-0"
                  data-aos="fade-up"
                >
                  Make Better <br /> Credit Decisions
                </p>
                {/* <p className="homepage_left_item1Text2"></p> */}
                <p
                  className="homepage_left_item1Text3 my-3 font-resize"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  Alternate database of chronic defaulters & AI-powered Debt
                  Recovery{" "}
                </p>
                <div data-aos="fade-up" data-aos-delay="800">
                  <Button
                    className="btn btn-danger mr-4 my-4 homepage_left_button"
                    data-bs-toggle="modal"
                    data-bs-target="#requestDemo"
                  >
                    Request Demo
                  </Button>

                  <a href="#">
                    <img src={play} alt="" className="youtube-btn" />
                  </a>
                </div>
              </div>
            </div>
          </Col>

          {imageFix ? (
            <div
              className="homepage_right  pe-0 d-flex justify-content-end mb-5 img-fluid"
              data-aos="fade-left"
            >
              <img
                className="img image_banner  mb-3  "
                data-aos="fade-left"
                src={otherImage ? hero2 : hero}
                alt=""
              />
            </div>
          ) : (
            <div
              className="homepage_right pt-4 pe-0 col-auto mb-5  "
              data-aos="fade-left"
            >
              <img
                className="img image_banner  "
                data-aos="fade-left"
                src={hero}
                alt=""
              />
            </div>
          )}
        </Row>
      </section>
      <section
        className="homepage_2 py-3 pb-5 w-100 "
        style={{ overflowX: "hidden" }}
      >
        <Row className="homepage_2_content d-flex justify-space-between gx-5 my-5 w-100 mx-0">
          <div className="py-5 col-auto order-last order-lg-first order-xxl-first order-xl-first ps-0 ">
            <img className="map pt-5 me-5" src={map} data-aos="fade-up" />
          </div>
          <Col xxl={5} xl={5} lg={5} md={5} sm={12} className="pt-2 mt-3  ">
            <p className="second_level_header mt-3 mb-0" data-aos="fade-up">
              Who we are
            </p>

            <p
              className="second_level_header_text_one py-2 mb-3"
              data-aos="fade-up"
            >
              We are a Credit Management Company helping businesses make better
              credit decisions and borrowers to become debt-free.{" "}
            </p>
            <p
              className="second_level_header_text_one py-1 my-4 "
              data-aos="fade-up"
            >
              {" "}
              Non-performing loans are a big issue for the credit industry, both
              for lenders and borrowers. Businesses need to be proactive in
              reducing bad loans, this would mean investing in bad debt
              prevention and this requires an increased focus on risk assessment
              prior to giving out credit.
            </p>
            <Link class="nav-link active" to="/aboutus">
              <Button
                className="homepage_left_button_learn my-2 btn btn-outline-danger"
                data-aos="fade-up"
              >
                Learn more
              </Button>
            </Link>
          </Col>
        </Row>
      </section>
      <ProductOfferingSection />
      <GetridCaptionSection />
      <section className="homepage_5 py-5">
        <p className="second_level_header text-center " data-aos="fade-up">
          Featured on
        </p>
        <Container>
          <Row>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="d-flex">
              <a
                className=" mx-auto "
                href="https://techpoint.africa/2020/11/03/blacklist-chronic-debtors-nigeria/"
                target="_blank"
              >
                <img
                  className="techpoint mx-auto py-4"
                  src={tech}
                  data-aos="zoom-out"
                  data-aos-delay="800"
                />
              </a>
            </Col>

            <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="d-flex">
              <a
                className=" mx-auto "
                href="https://venturesafrica.com/blacklist-the-nigerian-startup-eliminating-chronic-debtors-one-search-at-a-time/"
                target="_blank"
              >
                <img
                  className="venture mx-auto py-4"
                  src={venture}
                  data-aos="zoom-out"
                  data-aos-delay="800"
                />
              </a>
            </Col>

            <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="d-flex">
              <a
                className=" mx-auto "
                href="https://www.linkedin.com/posts/ndubuisi-ekekwe-36068210_good-people-join-me-to-welcome-blacklistng-activity-6803320297039679488-_377"
                target="_blank"
              >
                <img
                  className="teke mx-auto py-4"
                  src={teke}
                  data-aos="zoom-out"
                  data-aos-delay="800"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Colaborations/>
          </Row>
        </Container>
      </section>
      <div
        class="modal fade"
        id="requestDemo"
        tabindex="-1"
        aria-labelledby="requestDemoLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content w-100">
            <div class="modal-header d-flex justify-content-end pb-0">
              <button
                type="button"
                class="btn cancel"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={close} />
              </button>
            </div>
            <div class="modal-body modal-body-alt pb-0 ">
              {success ? (
                <img src={successimg} />
              ) : (
                <form id="modalForm" onSubmit={sendEmail}>
                  <div class="mb-3 row">
                    <h3
                      className="text-center mb-5"
                      style={{ color: "#4b1616" }}
                    >
                      Demo Request Form
                    </h3>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label for="fname" class="form-label" data-aos="fade-up">
                        First Name
                      </label>
                      <input
                        type="text"
                        class="form-control support-input"
                        name="fname"
                        value={form.fname}
                        onChange={updateForm}
                        aria-describedby="emailHelp"
                        placeholder="First Name"
                        data-aos="fade-up"
                      />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label for="lname" class="form-label" data-aos="fade-up">
                        Last Name
                      </label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        class="form-control support-input"
                        value={form.lname}
                        onChange={updateForm}
                        name="lname"
                        data-aos="fade-up"
                      />
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label for="email" class="form-label" data-aos="fade-up">
                        Email
                      </label>
                      <input
                        type="Email"
                        class="form-control support-input"
                        name="email"
                        value={form.email}
                        onChange={updateForm}
                        placeholder="Email"
                        aria-describedby="emailHelp"
                        data-aos="fade-up"
                      />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label for="cname" class="form-label" data-aos="fade-up">
                        Company Name
                      </label>
                      <input
                        type="text"
                        class="form-control support-input"
                        name="cname"
                        value={form.cname}
                        onChange={updateForm}
                        placeholder="Company name"
                        aria-describedby="emailHelp"
                        data-aos="fade-up"
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="selector" class="form-label" data-aos="fade-up">
                      Your Inquiry is about?
                    </label>
                    <select
                      className="w-100 form-control select-input"
                      name="inquiry"
                      value={form.inquiry}
                      onChange={updateForm}
                    >
                      <option selected value="request Demo">
                        Request Demo
                      </option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label for="selector" class="form-label" data-aos="fade-up">
                      Message
                    </label>
                    <textarea
                      value={form.message}
                      onChange={updateForm}
                      className="form-control support-input-fill"
                      name="message"
                      data-aos="fade-up"
                    ></textarea>
                  </div>
                  <div class="modal-footer pt-0 w-100 pb-4 px-0">
                    <div className="btn-ctn d-flex w-100 justify-content-end ">
                      <Button
                        className="btn btn-danger  homepage_left_button"
                        type="submit"
                        value="Send"
                      >
                        Send Message
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
