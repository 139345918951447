import Aos from "aos";
import React, { Component, useEffect } from "react";
import styled from "styled-components";
import Footer from "../../component/footer";
import GetridCaptionSection from "../../component/GetridCaptionSection.jsx/GetridCaptionSection";
import HeroSection from "../../component/herosection/HeroSection";
import NavCtn from "../../component/navbar";
import arrowright from "../../img/arrow-right.svg";
import "./Blog.css";
import "aos/dist/aos.css";


const Blog = () => {
  
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false, disable: "mobile" })
  }
  , [])
   
  return (
    <div className="overflow-hidden w-100">
      <NavCtn />
      <HeroSection captionTop="Make Better" captionBottom="Credit Decisions" />
      <section className="w-100 overflow-hidden middle-section py-5">
        <div className="row row-fix  px-5 gy-5 my-4 justify-content-start">
          <BlogCard
            caption="Announcing Partnership with CRC Credit Bureau"
            date="2nd November 2021"
            link="https://medium.com/@blacklistnigeria/blacklist-limited-enters-strategic-partnership-with-crc-credit-bureau-nigeria-c900c1f9ba5a"
          />{" "}
          <BlogCard
            caption=" Emerging Tools And Opportunities For Fintechs In The Nigerian Credit Sector"
            date="3rd November 2021" 
            link="https://medium.com/@blacklistnigeria/lending-2-0-emerging-tools-and-opportunities-for-fintechs-in-the-nigerian-credit-sector-629cd084f513"
          />
          {/* <BlogCard
            caption="Announcing Partnership with CRC Credit Bureau"
            date="2nd October 2021"
            link="#"
          />
          <BlogCard
            caption="Announcing Partnership with CRC Credit Bureau"
            date="2nd October 2021"
            link="#"
          />
          <BlogCard
            caption="Announcing Partnership with CRC Credit Bureau"
            date="2nd October 2021"
            link="#"
          />
          <BlogCard
            caption="Announcing Partnership with CRC Credit Bureau"
            date="2nd October 2021"
            link="#"
          />
          <BlogCard
            caption="Announcing Partnership with CRC Credit Bureau"
            date="2nd October 2021"
            link="#"
          />
          <BlogCard
            caption="Announcing Partnership with CRC Credit Bureau"
            date="2nd October 2021"
            link="#"
          />
          <BlogCard
            caption="Announcing Partnership with CRC Credit Bureau"
            date="2nd October 2021"
            link="#"
          /> */}
        </div>
      </section>
      <GetridCaptionSection />
      <Footer />
    </div>
  );
};

export default Blog;

const CardTitle = styled.h3`
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #4b1616;
  height: 55px;
`;

const BlogCard = (props) => {
  return (
    <div
      className="col-auto "
      data-aos="fade-up"
      style={{ width: "fit-content" }}
      //   onMouseOver={Swell}
    >
      <div className="card border-0 blog-card h-100">
        <div className="card-body pt-5 pb-4">
          <div className="pb-5">
            <span className="card-header"> Blog Post</span>
          </div>
          <div>
            <CardTitle>{props.caption}</CardTitle>
          </div>
          <p className="blogcard-date my-3">{props.date}</p>

          <a href={props.link} target="_blank" className="card-link">
            Read the story
            <img
              src={arrowright}
              className="arrow-right"
              alt="click to read more"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
