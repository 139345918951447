import React, { Component, useState } from "react";
import Homepage from "./pages/homepage";
import Solutions from "./pages/solutions";
import AboutUs from "./pages/aboutus/aboutus";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Blog from "./pages/blog/Blog";
import Support from "./pages/support/Support";
import { emailjs, send } from "emailjs-com";

const App = () => {
  
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        <Route path="/solutions" exact element={<Solutions />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/support" exact element={<Support />} />
        <Route path="/aboutus" exact element={<AboutUs />} />
      </Routes>
    </Router>
  );
};

export default App;
