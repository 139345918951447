import React, { Component } from "react";
import quickcheck from "../../img/quickcheck.png";
import fairmoney from "../../img/fairmoney-logo.png";
import aladdin from "../../img/aladdin-logo.svg";
import credpal from "../../img/credpal-logo.svg";
import entourage from "../../img/entourage.png";
import borome from "../../img/borome-logo.png";
import avro from "../../img/avrofinance-logo.png";
import kiakia from "../../img/kiakia-logo.png";
import libertyassured from "../../img/libertyassured-logo.webp";
import evolvecredit from "../../img/evolvecredit-logo.png";
import shepherdtrust from "../../img/shepherdtrust-logo.png";

import "./style.scss";
const Colaborations = () => {
  return (
    <>
      {" "}
      <section className="colaborations-section py-4 mt-5 w-100 pb-5 mb-5">
        <p className="second_level_header text-center mb-5" data-aos="fade-up">
          Trusted By
        </p>{" "}
        <div className="swiper mySwiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              {" "}
              <a
                href="https://quickcheck.ng/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={quickcheck} />
              </a>
            </div>
            <div className="swiper-slide">
              {" "}
              <a
                href="https://fairmoney.io/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={fairmoney} />
              </a>
            </div>
            <div className="swiper-slide">
              <a
                href="https://www.aladdin.ng/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={aladdin} />
              </a>
            </div>
            <div className="swiper-slide">
              {" "}
              <a
                href="https://credpal.com/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={credpal} />
              </a>
            </div>
            <div className="swiper-slide">
              {" "}
              <a
                href="https://www.libertyassured.com/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={libertyassured} />
                <span className="ms-2">Liberty</span>
              </a>
            </div>
            <div className="swiper-slide">
              {" "}
              <a
                href="https://www.borome.ng/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={borome} />
              </a>
            </div>
            <div className="swiper-slide">
              {" "}
              <a
                href="https://www.getaloan.ng/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={shepherdtrust} />
              </a>
            </div>
            <div className="swiper-slide">
              {" "}
              <a
                href="https://entourageloans.com/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={entourage} />
              </a>
            </div>
            <div className="swiper-slide">
              {" "}
              <a
                href="https://www.arvofinance.com/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={avro} />
              </a>
            </div>
            <div className="swiper-slide">
              {" "}
              <a
                href="https://kiakia.co/
"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={kiakia} />
              </a>
            </div>{" "}
            <div className="swiper-slide">
              {" "}
              <a
                href="https://www.evolvecredit.co/"
                className="carousel-link-item"
                target="_blank"
              >
                <img src={evolvecredit} />
              </a>
            </div>
          </div>
          
          <div className="swiper-pagination"></div>
        </div>
      </section>
    </>
  );
};

export default Colaborations;
