import React, { useEffect } from "react";
import logo from "../../img/logo.svg";
import FB from "../../img/fb.png";
import insta from "../../img/insta-icon.png";
import twitter from "../../img/twitter.png";
import linkedin from "../../img/linkedin-icon.svg";

import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import Aos from "aos";

function Footer() {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true, mirror: false });
  });
  return (
    <section className="footer-section py-3 px-4">
      <Row className="mx-lg-5 my-5 ">
        <Col xxl={3} xl={3} lg={3} md={12} sm={12} className="d-flex">
          <div className="   pb-4">
            <div className="d-flex w-100">
              <img className="logo " src={logo} data-aos="fade-up" />
            </div>
            <p
              className="footer-subtitle mt-4 mb-3"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              Alternate database of chronic defaulters <br /> & AI-powered debt
              recovery
            </p>
          </div>
        </Col>

        <Col xxl={2} xl={2} lg={2} md={6} sm={6} xs={6} className="d-flex">
          <div className="footer-list-ctn ">
            <p className="footer-title" data-aos="fade-up">
              Company
            </p>
            <ul
              className="footer-list "
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <li>
                <Link to="/" className="nav-link" target="_top">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/aboutus" className="nav-link" target="_top">
                  About us
                </Link>
              </li>
              <li>
                <Link to="/blog" className="nav-link" target="_top">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/support" className="nav-link" target="_top">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </Col>

        <Col
          xxl={2}
          xl={2}
          lg={2}
          md={6}
          sm={6}
          xs={6}
          className="d-flex justifier"
        >
          <div className="footer-list-ctn ">
            {" "}
            <p className="footer-title" data-aos="fade-up">
              Resources
            </p>
            <ul className="footer-list" data-aos-delay="500" data-aos="fade-up">
              <li>
                <a href="https://blacklistng.com/docs" className="nav-link">
                  Developers
                </a>
              </li>
              {/* <li>
                <a href="#" className="nav-link">
                  Documentation
                </a>
              </li> */}
              <li>
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#requestDemo"
                  href="#requestDemo"
                  className="nav-link"
                >
                  Request Demo
                </a>
              </li>
            </ul>
          </div>
        </Col>

        <Col xxl={2} xl={2} lg={2} md={6} sm={6} xs={6} className="d-flex">
          <div className="footer-list-ctn ">
            <p className="footer-title" data-aos="fade-up">
              Legal
            </p>
            <ul className="footer-list" data-aos-delay="500" data-aos="fade-up">
              {/* <li>
                <Link to="/developers" className="nav-link">
                  Developer Policy
                </Link>
              </li> */}
              <li>
                <Link to="/aboutus" className="nav-link">
                  Privacy Policy
                </Link>
              </li>
              {/* <li>
                <Link to="/aboutus" className="nav-link">
                  Cookie Policy
                </Link>
              </li> */}
            </ul>
          </div>
        </Col>

        <Col
          xxl={3}
          xl={3}
          lg={3}
          md={6}
          sm={6}
          xs={6}
          className="d-flex justifier"
        >
          <div className="footer-list-ctn  pe-sm-3 ">
            <p className="footer-title " data-aos="fade-up">
              Contact us
            </p>
            <ul data-aos-delay="500" data-aos="fade-up">
              <li style={{ whiteSpace: "nowrap" }}>
                {" "}
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/blacklistng"
                  className="me-3 me-lg-4 me-xxl-4 me-xl-4"
                >
                  <img className="linkedin " src={linkedin} />
                </a>
                <a
                  target="_blank"
                  href=" https://www.facebook.com/search/top/?q=blacklist%20nigeria"
                  className="me-3 me-lg-4 me-xxl-4 me-xl-4"
                >
                  <img className="fb " src={FB} />
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/Blacklist_ng?t=aZDAvgs9ftwDA4iswf693A&s=09"
                  className="me-3 me-lg-4 me-xxl-4 me-xl-4"
                >
                  <img className="twitter " src={twitter} />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/blacklist_ng/"
                  className="me-3 me-lg-4 me-xxl-4 me-xl-4"
                >
                  <img className="insta " src={insta} />
                </a>{" "}
                
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://blacklistng.com/docs"
                  className="nav-link mt-3 font-reduce"
                >
                  info@blacklistng.com
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <div className="row mx-3" data-aos="fade-up">
        <div
          className="col-sm-12 d-none d-lg-block"
          style={{ height: "60px" }}
        ></div>
      </div>
    </section>
  );
}

export default Footer;
